import React, { useEffect } from "react";
import ReactGA from "react-ga4";
import {
  ArrowPathIcon,
  CloudArrowUpIcon,
  Cog6ToothIcon,
  FingerPrintIcon,
  LockClosedIcon,
  ServerIcon,
} from "@heroicons/react/20/solid";

import PublicHeader from "../components/PublicHeader";
import Footer from "../components/Footer";

const faqs = [
  {
    id: 1,
    question: "Are my entries private and secure?",
    answer:
      "Yes, your privacy and security are our top priorities. Your notes are encrypted, we do not look at your data, nor is your data used to train any AI. We follow industry best practices for data protection and regularly update our security measures to safeguard your personal information.",
  },
  {
    id: 2,
    question: "Is Echo free to use?",
    answer:
      "Yes, the Echo beta is completely free to use. We believe that everyone should have access to tools that help them reflect and grow. Eventually, we're planning to introduce premium features to enhance your journaling experience even further.",
  },
  {
    id: 3,
    question: "How does Echo use AI?",
    answer:
      "Echo leverages the GPT-4o large language model for transcription, recall, and insight generation. When you record your voice, the AI transcribes your entries accurately. You can ask the AI questions about your past reflections, and it will search through your entries to provide meaningful and insightful answers, making your journaling experience more interactive and engaging.",
  },
];

export default function HomePage() {
  // Google Analytics
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: "/",
      title: "Home Page",
    });
  }, []);

  return (
    <div>
      <PublicHeader />
      {/* Hero */}
      <div className="relative isolate overflow-hidden bg-white px-6 pt-32 sm:pt-60 lg:overflow-visible lg:px-8">
        <div className="absolute inset-0 -z-10 overflow-hidden">
          <svg
            aria-hidden="true"
            className="absolute left-[max(50%,25rem)] top-0 h-[64rem] w-[128rem] -translate-x-1/2 stroke-gray-200 [mask-image:radial-gradient(64rem_64rem_at_top,white,transparent)]"
          >
            <defs>
              <pattern
                x="50%"
                y={-1}
                id="e813992c-7d03-4cc4-a2bd-151760b470a0"
                width={200}
                height={200}
                patternUnits="userSpaceOnUse"
              >
                <path d="M100 200V.5M.5 .5H200" fill="none" />
              </pattern>
            </defs>
            <svg x="50%" y={-1} className="overflow-visible fill-gray-50">
              <path
                d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M-300.5 600h201v201h-201Z"
                strokeWidth={0}
              />
            </svg>
            <rect
              fill="url(#e813992c-7d03-4cc4-a2bd-151760b470a0)"
              width="100%"
              height="100%"
              strokeWidth={0}
            />
          </svg>
        </div>
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto text-center">
            {/* Product Hunt Button */}
            <div className="mb-6 flex justify-center">
              <a
                href="https://www.producthunt.com/posts/echo-76b8e6a3-3f29-4842-9927-6d0f0b08f0fa?embed=true&utm_source=badge-featured&utm_medium=badge&utm_souce=badge-echo&#0045;76b8e6a3&#0045;3f29&#0045;4842&#0045;9927&#0045;6d0f0b08f0fa"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=489251&theme=light"
                  alt="Echo - Capture&#0032;easier&#0044;&#0032;think&#0032;better&#0032;with&#0032;AI&#0032;voice&#0032;and&#0032;text&#0032;notes | Product Hunt"
                  style={{ width: "250px", height: "54px" }}
                  width="250"
                  height="54"
                />
              </a>
            </div>
            <h1 className="mt-6 text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
              Capture easier, think better
            </h1>
            <p className="mt-6 text-xl mx-auto max-w-xl leading-8 text-gray-700">
              Echo is a voice and text note-taking app that uses AI to organize
              and refine your thoughts.
            </p>
            <a
              href="https://apps.apple.com/us/app/echo-ai-notes/id6503351019"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="/app-store-button.png"
                alt="Download on the App Store"
                className="mt-8 w-32 inline mx-auto sm:mx-0"
              />
            </a>
          </div>
        </div>
      </div>
      {/* Demo video */}
      <div className="relative w-full max-w-4xl mx-auto my-20">
        <div
          style={{ position: "relative", paddingBottom: "56.25%", height: 0 }}
        >
          <iframe
            src="https://www.loom.com/embed/5ad6f60709a444cfa9b67681c786bcca?sid=12704dc4-3a95-4c91-8bf6-2299426df8d1"
            allowFullScreen
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
            }}
          ></iframe>
        </div>
      </div>
      {/* How it works section 1 */}
      <div className="mx-auto max-w-5xl px-6 py-12 flex flex-col lg:flex-row justify-between items-center lg:px-8 lg:py-30">
        <div className="w-full lg:w-[45%]">
          <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Capture your thoughts
          </h1>
          <p className="mt-4 text-lg leading-8 text-gray-700">
            1-click voice notes make this the easiest way to capture your
            thoughts in the moment.
          </p>
        </div>
        <div className="w-full lg:w-[45%] sm:w-[60%]">
          <div className="px-12">
            <img
              alt=""
              src="/illustration-walk.png"
              className="w-full max-w-full"
            />
          </div>
        </div>
      </div>
      {/* How it works section 2 */}
      <div className="mx-auto max-w-5xl px-6 py-12 sm:py-10 flex flex-col-reverse lg:flex-row justify-between items-center lg:px-8 lg:py-30">
        <div className="lg:w-[45%]">
          <div className="px-12">
            <svg
              viewBox="0 0 366 729"
              role="img"
              className="mx-auto w-[22.875rem] max-w-full drop-shadow-xl"
            >
              <title>App screenshot</title>
              <defs>
                <clipPath id="2ade4387-9c63-4fc4-b754-10e687a0d332">
                  <rect width={316} height={684} rx={36} />
                </clipPath>
              </defs>
              <path
                fill="#4B5563"
                d="M363.315 64.213C363.315 22.99 341.312 1 300.092 1H66.751C25.53 1 3.528 22.99 3.528 64.213v44.68l-.857.143A2 2 0 0 0 1 111.009v24.611a2 2 0 0 0 1.671 1.973l.95.158a2.26 2.26 0 0 1-.093.236v26.173c.212.1.398.296.541.643l-1.398.233A2 2 0 0 0 1 167.009v47.611a2 2 0 0 0 1.671 1.973l1.368.228c-.139.319-.314.533-.511.653v16.637c.221.104.414.313.56.689l-1.417.236A2 2 0 0 0 1 237.009v47.611a2 2 0 0 0 1.671 1.973l1.347.225c-.135.294-.302.493-.49.607v377.681c0 41.213 22 63.208 63.223 63.208h95.074c.947-.504 2.717-.843 4.745-.843l.141.001h.194l.086-.001 33.704.005c1.849.043 3.442.37 4.323.838h95.074c41.222 0 63.223-21.999 63.223-63.212v-394.63c-.259-.275-.48-.796-.63-1.47l-.011-.133 1.655-.276A2 2 0 0 0 366 266.62v-77.611a2 2 0 0 0-1.671-1.973l-1.712-.285c.148-.839.396-1.491.698-1.811V64.213Z"
              />
              <path
                fill="#343E4E"
                d="M16 59c0-23.748 19.252-43 43-43h246c23.748 0 43 19.252 43 43v615c0 23.196-18.804 42-42 42H58c-23.196 0-42-18.804-42-42V59Z"
              />
              <foreignObject
                width={316}
                height={684}
                transform="translate(24 24)"
                clipPath="url(#2ade4387-9c63-4fc4-b754-10e687a0d332)"
              >
                <img src="/iphone-mock-topics-home.png" alt="" />
              </foreignObject>
            </svg>
          </div>
        </div>
        <div className="w-full lg:w-[45%]">
          <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Automatic organization
          </h1>
          <p className="mt-4 text-lg leading-8 text-gray-700 pb-10">
            Watch as your notes are automatically organized into topics, giving
            dedicated spaces to explore a subject.
          </p>
        </div>
      </div>
      {/* How it works section 3 */}
      <div className="mx-auto max-w-5xl px-6 py-12 flex flex-col sm:flex-col-reverse lg:flex-row justify-between items-center lg:px-8 lg:py-30">
        <div className="w-full lg:w-[45%]">
          <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Bring your notes to life
          </h1>
          <p className="mt-4 text-lg leading-8 text-gray-700  pb-10">
            Ask Echo for anything related to your notes, including summaries,
            analysis, and content creation.
          </p>
        </div>
        <div className="lg:w-[45%]">
          <div className="px-12">
            <svg
              viewBox="0 0 366 729"
              role="img"
              className="mx-auto w-[22.875rem] max-w-full drop-shadow-xl"
            >
              <title>App screenshot</title>
              <defs>
                <clipPath id="2ade4387-9c63-4fc4-b754-10e687a0d332">
                  <rect width={316} height={684} rx={36} />
                </clipPath>
              </defs>
              <path
                fill="#4B5563"
                d="M363.315 64.213C363.315 22.99 341.312 1 300.092 1H66.751C25.53 1 3.528 22.99 3.528 64.213v44.68l-.857.143A2 2 0 0 0 1 111.009v24.611a2 2 0 0 0 1.671 1.973l.95.158a2.26 2.26 0 0 1-.093.236v26.173c.212.1.398.296.541.643l-1.398.233A2 2 0 0 0 1 167.009v47.611a2 2 0 0 0 1.671 1.973l1.368.228c-.139.319-.314.533-.511.653v16.637c.221.104.414.313.56.689l-1.417.236A2 2 0 0 0 1 237.009v47.611a2 2 0 0 0 1.671 1.973l1.347.225c-.135.294-.302.493-.49.607v377.681c0 41.213 22 63.208 63.223 63.208h95.074c.947-.504 2.717-.843 4.745-.843l.141.001h.194l.086-.001 33.704.005c1.849.043 3.442.37 4.323.838h95.074c41.222 0 63.223-21.999 63.223-63.212v-394.63c-.259-.275-.48-.796-.63-1.47l-.011-.133 1.655-.276A2 2 0 0 0 366 266.62v-77.611a2 2 0 0 0-1.671-1.973l-1.712-.285c.148-.839.396-1.491.698-1.811V64.213Z"
              />
              <path
                fill="#343E4E"
                d="M16 59c0-23.748 19.252-43 43-43h246c23.748 0 43 19.252 43 43v615c0 23.196-18.804 42-42 42H58c-23.196 0-42-18.804-42-42V59Z"
              />
              <foreignObject
                width={316}
                height={684}
                transform="translate(24 24)"
                clipPath="url(#2ade4387-9c63-4fc4-b754-10e687a0d332)"
              >
                <img src="/iphone-mock-response.png" alt="" />
              </foreignObject>
            </svg>
          </div>
        </div>
      </div>
      {/* Founder testimonials */}
      <section className="bg-white py-24 sm:py-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto grid max-w-2xl grid-cols-1 lg:mx-0 lg:max-w-none lg:grid-cols-2">
            <div className="flex flex-col pb-10 sm:pb-16 lg:pb-0 lg:pr-8 xl:pr-20">
              <figure className="mt-10 flex flex-auto flex-col justify-between">
                <blockquote className="text-lg leading-8 text-gray-900">
                  <p>
                    “Echo is my favorite way to think about work - walk and
                    talk. Give it a go!”
                  </p>
                </blockquote>
                <figcaption className="mt-10 flex items-center gap-x-6">
                  <img
                    alt=""
                    src="/headshot-reuben.jpeg"
                    className="h-14 w-14 rounded-full bg-gray-50"
                  />
                  <div className="text-base">
                    <div className="font-semibold text-gray-900">
                      Reuben Abraham
                    </div>
                    <div className="mt-1 text-gray-500">Cofounder, CEO</div>
                  </div>
                </figcaption>
              </figure>
            </div>
            <div className="flex flex-col border-t border-gray-900/10 pt-10 sm:pt-16 lg:border-l lg:border-t-0 lg:pl-8 lg:pt-0 xl:pl-20">
              <figure className="mt-10 flex flex-auto flex-col justify-between">
                <blockquote className="text-lg leading-8 text-gray-900">
                  <p>
                    “Echo helps me clarify my thinking and keep track of my
                    goals. I think of Echo as a living notebook, and it's my
                    favorite thing to read when I'm killing time on my phone.”
                  </p>
                </blockquote>
                <figcaption className="mt-10 flex items-center gap-x-6">
                  <img
                    alt=""
                    src="/headshot-thomas.png"
                    className="h-14 w-14 rounded-full bg-gray-50"
                  />
                  <div className="text-base">
                    <div className="font-semibold text-gray-900">
                      Thomas F. Officer
                    </div>
                    <div className="mt-1 text-gray-500">Cofounder</div>
                  </div>
                </figcaption>
              </figure>
            </div>
          </div>
        </div>
      </section>

      {/* Call to action */}
      <div className="bg-white">
        <div className="px-6 py-24 sm:px-6 sm:py-32 lg:px-8">
          <div className="mx-auto max-w-2xl text-center">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              Ready to get started?
              <br />
              Download Echo for free.
            </h2>
            <p className="mx-auto mt-6 max-w-xl text-lg leading-8 text-gray-600">
              Start building your intelligent notebook and discover the benefits
              of organized thoughts and intelligent recall.
            </p>
            <div className="mt-10 flex items-center justify-center gap-x-6">
              <a
                href="https://apps.apple.com/us/app/echo-ai-notes/id6503351019"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="/app-store-button.png"
                  alt="Download on the App Store"
                  className="w-32 inline mx-auto sm:mx-0"
                />
              </a>
              <a
                href="/about"
                className="text-sm font-semibold leading-6 text-gray-900"
              >
                About us <span aria-hidden="true">→</span>
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* FAQ section */}
      <div className="mx-auto max-w-5xl divide-y divide-gray-900/10 px-6 py-24 sm:py-32 lg:px-8 lg:py-40">
        <h2 className="text-2xl font-bold leading-10 tracking-tight text-gray-900">
          Frequently asked questions
        </h2>
        <dl className="mt-10 space-y-8 divide-y divide-gray-900/10">
          {faqs.map((faq) => (
            <div key={faq.id} className="pt-8 lg:grid lg:grid-cols-12 lg:gap-8">
              <dt className="text-base font-semibold leading-7 text-gray-900 lg:col-span-5">
                {faq.question}
              </dt>
              <dd className="mt-4 lg:col-span-7 lg:mt-0">
                <p className="text-base leading-7 text-gray-600">
                  {faq.answer}
                </p>
              </dd>
            </div>
          ))}
        </dl>
      </div>
      <Footer />
    </div>
  );
}
